import { useEffect } from 'react';
import { useApp } from '../store';

export const useIsMobile = () => {
  const set = useApp((s) => s.set);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      set({ isMobile: window.innerWidth <= 768 });
    };

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [set]);
};
