import axios from 'axios';
import { notification } from 'antd';

const ConnectionHandler = () => {
  const exitFromApp = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('password');
  };

  axios.interceptors.response.use(
    (config) => config,
    (err) => {
      const url = err.config?.url;
      const status = err.response?.status ?? 401;
      const response = err.response?.data;
      let message = typeof response === 'string' ? response : response?.message ?? 'Ha ocurrido un error';

      if (url === '/login/google') {
        if (status === 409) {
          message = 'El inicio de sesión con Google no está habilitado para esta cuenta.';
        }
      }

      if (status === 401) {
        exitFromApp();
      }
      notification.error({ message });
      throw err;
    }
  );

  return null;
};

export default ConnectionHandler;
