import { lazy, Suspense } from 'react';
import AppLoading from './layout/utils/AppLoading';
import { useLoadData } from './hooks/useLoadData';
import { useApp } from './store';
import axios from 'axios';
import { API_KEY, BASE_URL } from './constants/api';
import { useIsMobile } from './hooks/useIsMobile';

const MainContainer = lazy(() => import('./containers/MainContainer'));
const AuthContainer = lazy(() => import('./containers/AuthContainer'));

const App = () => {
  const dataLoaded = useApp((s) => s.dataLoaded);

  useLoadData();
  useIsMobile();

  return <Suspense fallback={<AppLoading />}>{dataLoaded ? <MainContainer /> : <AuthContainer />}</Suspense>;
};

export default App;

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers = {
  'x-api-key': API_KEY,
};
