import ReactDOM from 'react-dom';
import App from './App';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/es/locale/es_ES';
import ConnectionHandler from './connection/ConnectionHandler';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './styles/index.less';
import './assets/fonts/index.less';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <BrowserRouter>
    <ConfigProvider locale={es_ES}>
      <App />
      <ConnectionHandler />
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
