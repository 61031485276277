// https://testing-core.deploy.kuarasoftware.es/

export const API_KEY = 'Ml95mrmOGMfxz8eAKRalfnMWoLDg35IKYK';
export const BASE_URL = 'https://kore-api.kuarasoftware.es';

export const API = {
  auth: {
    login: `/kuaraapps/login`,
    user: (userId) => `/kuaraapps/user/${userId}`,
  },
  apps: {
    get: `/kuaraapps/apps`,
  },
  companies: {
    get: `/company`,
  },
  users: {
    /* app permission */
    addPermission: `/kuaraapps/user/permission`,
    removePermission: (userId, appId) => `/kuaraapps/user/permission/${userId}/${appId}`,
    /* ------------ */

    create: `/kuaraapps/user/register`,
    edit: (userId) => `/kuaraapps/user/${userId}`,
    delete: (userId) => `/kuaraapps/user/${userId}`,
    hard_delete: (userId) => `/kuaraapps/user/delete/${userId}`,
    get_all: `/kuaraapps/user/all`,
  },
};
