// must be on order
const ROLES = {
  kore_admin: {
    label: 'Kore Admin',
    role: 'b8eaa583',
  },
  kore_user: {
    label: 'Kore User',
    role: '0d4bdfd5',
  },
  app_superadmin: {
    label: 'Sup. Admin',
    role: '8fc02de6',
  },
  app_admin: {
    label: 'Admin',
    role: 'a2470ee4',
  },
  app_user: {
    label: 'Usuario',
    role: '97f567cf',
  },
};

export const AppRoles = {
  SUPER_ADMIN: '8fc02de6',
  ADMIN: 'a2470ee4',
  USER: '97f567cf',
};

export const KUARA_ROLES = [ROLES.kore_admin.role, ROLES.kore_user.role];

export const ROLES_RAW = Object.values(ROLES).map((r) => r.role);

export const ROLE_OPTS = Object.values(ROLES).map((r) => ({ label: r.label, value: r.role }));
export const APP_ROLE_OPTS = Object.values(ROLES)
  .filter((x) => Object.values(AppRoles).includes(x.role))
  .map((r) => ({ label: r.label, value: r.role }));
