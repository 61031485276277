import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApp } from '../store';
import { Actions } from '../actions';
import { AppRoles } from '../constants/permissions';

export const useLoadData = () => {
  const [params] = useSearchParams();
  const set = useApp((s) => s.set);
  const dataLoaded = useApp((s) => s.dataLoaded);
  const setToken = useApp((s) => s.setToken);
  const appToken = useApp((s) => s.token);
  const navigate = useNavigate();

  const from = params.get('from');

  useEffect(() => {
    const token = params.get('token');

    const loadData = async () => {
      set({ loading: true });
      const profile = await Actions.get('/user/profile').catch(() => null);

      if (!profile) {
        set({ loading: false });
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        return;
      }

      const role = profile.role;

      const apps = await Actions.get('/app/all');

      if (role === AppRoles.SUPER_ADMIN) {
        const [apps, users, companies, establishment] = await Promise.all(superAdminFetch.map(Actions.get)).catch([]);
        set({ apps, users, companies, establishment });
      }

      set({ dataLoaded: true, profile, apps, loading: false });
      navigate({ search: '' }, { replace: true });
    };

    if (token && !appToken) {
      setToken(token);
    } else if (appToken && !dataLoaded) {
      localStorage.removeItem('username');
      localStorage.removeItem('password');

      if (from) {
        window.location.href = `${from}?token=${appToken}`;
      } else {
        loadData();
      }
    }
  }, [set, appToken, setToken, params, dataLoaded, from, navigate]);
};

const superAdminFetch = ['/kuaraapps/apps', '/kuaraapps/user/all', '/company', '/establishment'];
