import { create } from 'zustand';
import axios from 'axios';
import { API_KEY } from '../constants/api';

export const useApp = create((set, get) => ({
  // AUTH
  token: null,
  googleToken: null,
  loading: false,

  // USER DATA
  dataLoaded: false,
  profile: null,
  establishment: null,

  // ESTABLISHMENT DATA
  apps: [],
  users: [],
  companies: [],

  // OTHERS
  showAppMenu: true,

  // ACTIONS
  set,
  upsert: (key, data) => {
    if (!data?._id) return;
    const value = get()[key];

    const index = value.findIndex((x) => x._id === data._id);

    if (index > -1) {
      const newValue = [...value];
      newValue[index] = data;
      set({ [key]: newValue });
      return;
    }
    set({ [key]: [...value, data] });
  },
  setToken: (token) => {
    axios.defaults.headers = {
      Authorization: `Bearer ${token}`,
      'x-api-key': API_KEY,
    };

    set({ token, googleToken: null });
  },
}));
